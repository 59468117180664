const commonConfig = {
  locale: "en-us",
  graphqlApiVersion: "2023-01",
};

export default {
  qa: {
    ...commonConfig,
    storeDomain: "dutch-pet-qa.myshopify.com",
    storefrontToken: "9cc1b3a2418bfe4682c314adc5f67996",
  },
  staging: {
    ...commonConfig,
    storeDomain: "dutch-pet-staging.myshopify.com",
    storefrontToken: "cc5edf56cf24af4de431f79c9b74ecc0",
  },
  production: {
    ...commonConfig,
    storeDomain: "dutchpet.myshopify.com",
    storefrontToken: "611e48949ef6d7bdd0c0bca90d57878a",
  },
};
