import {
  cacheExchange,
  createClient,
  DocumentInput,
  fetchExchange,
  OperationContext,
  OperationResult,
} from "@urql/core";
import shopifyConfig from "../../../.config/shopify/shopify";

type OperationFunction = <
  Data = unknown,
  Variables extends Record<string, unknown> = {},
>(
  query: DocumentInput<Data, Variables>,
  variables?: Variables,
  context?: Partial<OperationContext>
) => Promise<OperationResult<Data, Variables>>;

const useShopify = () => {
  const { storefrontENV } = useTheme();
  const env = shopifyConfig[storefrontENV];

  const client = createClient({
    url: `https://${env.storeDomain}/api/${env.graphqlApiVersion}/graphql.json`,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => ({
      headers: {
        "X-Shopify-Storefront-Access-Token": env.storefrontToken,
      },
    }),
  });

  const useQuery: OperationFunction = (query, variables, context) => {
    return client.query(query, variables as any, context).toPromise();
  };

  const useMutation: OperationFunction = (query, variables, context) => {
    return client.mutation(query, variables as any, context).toPromise();
  };

  return { client, useQuery, useMutation };
};

export default useShopify;
