import { gql } from "@urql/core";

export const orders = gql(/* GraphQL */ `
  fragment orders on Customer {
    orders(first: 200, sortKey: PROCESSED_AT, reverse: true) {
      nodes {
        customAttributes {
          key
          value
        }
        id
        orderNumber
        totalPriceV2 {
          amount
          currencyCode
        }
        discountApplications(first: 10) {
          edges {
            node {
              ... on DiscountCodeApplication {
                __typename
                code
              }
            }
          }
        }
        processedAt
        fulfillmentStatus
        financialStatus
        canceledAt
        name
        financialStatus
        fulfillmentStatus
        lineItems(first: 100) {
          edges {
            node {
              variant {
                id
                title
                sku
                image {
                  altText
                  url
                }
                price {
                  currencyCode
                  amount
                }
                product {
                  compareAtPriceRange {
                    maxVariantPrice {
                      amount
                      currencyCode
                    }
                    minVariantPrice {
                      amount
                      currencyCode
                    }
                  }
                  handle
                  id
                  title
                  tags
                  onlineStoreUrl
                  productType
                }
                sellingPlanAllocations(first: 20) {
                  edges {
                    node {
                      sellingPlan {
                        id
                        description
                        name
                        recurringDeliveries
                      }
                    }
                  }
                }
              }
              currentQuantity
              customAttributes {
                key
                value
              }
              title
              quantity
            }
          }
        }
        currentTotalPrice {
          amount
          currencyCode
        }
        successfulFulfillments {
          trackingCompany
          trackingInfo {
            number
            url
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);
