import { gql } from "@urql/core";

export const customerAccessTokenCreateWithMultipass = gql`
  mutation customerAccessTokenCreateWithMultipass(
    $multipassToken: String!
  ) {
    customerAccessTokenCreateWithMultipass(
      multipassToken: $multipassToken
    ) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`