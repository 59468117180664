import { gql } from "@urql/vue";

export const customerUpdateNameMutation = gql`
  mutation customerUpdateName(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customer {
        lastName
        firstName
      }
    }
  }
`;
