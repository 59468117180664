import {
  customerAccessTokenCreate,
  customerAccessTokenCreateWithMultipass,
  customerAccessTokenDelete,
  customerActivate,
  customerActivateByUrl,
  customerAddressCreate,
  customerAddressUpdate,
  customerCreate,
  customerRecover,
  resetPasswordByUrl,
} from "@/graphql/customer/mutations";
import { getCustomer } from "@/graphql/customer/queries";
import {
  Customer,
  CustomerAccessToken,
  CustomerAccessTokenCreateInput,
  CustomerActivateInput,
  CustomerCreateInput,
  CustomerUserError,
  MailingAddressInput,
} from "@/graphql/generated/graphql-operations";
import { parseGid } from "@/js/utils/shopify-gid";
import { CustomerAccessTokenDeleteInput, CustomerAddress } from "@/types/types";
import dayjs from "dayjs";
import { defineStore } from "pinia";

const { useQuery, useMutation } = useStorefront();

export const useCustomer = defineStore("customer", {
  state: () => ({
    customer: null as Customer | null,
    customerAccessToken: null as CustomerAccessToken | null,
    loading: false as boolean,
    customerUserErrors: [] as CustomerUserError[],
  }),
  actions: {
    async customerCreate(input: CustomerCreateInput) {
      const { data } = await useMutation(customerCreate, {
        input,
      });

      if (data?.customerCreate?.customerUserErrors?.length) {
        this.customerUserErrors = data.customerCreate.customerUserErrors;
      }
    },
    async login(email: string, password: string) {
      try {
        this.loading = true;
        this.customerUserErrors = [];
        await this.customerAccessTokenCreate({ email, password });

        if (!this.customer && this.customerAccessToken) {
          await this.getCustomer();
        }
      } catch (e) {
        return e;
      } finally {
        this.loading = false;
      }
    },
    async logout() {
      try {
        this.loading = true;

        if (this.customerAccessToken?.accessToken) {
          await this.customerAccessTokenDelete(this.customerAccessToken);
        }

        this.customer = null;
        window.location.href = useSso().logoutUrl({
          searchParams: {
            return_to: `${window.location.protocol}//${window.location.host}/account/logout`,
          },
        });
      } catch (e) {
        return e;
      } finally {
        this.loading = false;
      }
    },
    async recover(email: string) {
      try {
        this.loading = true;

        await this.customerRecover(email);
      } catch (e) {
        return e;
      } finally {
        this.loading = false;
      }
    },
    async activate(id: string, input: CustomerActivateInput) {
      try {
        this.loading = true;
        this.customerUserErrors = [];
        const { customerActivate } = await this.customerActivateById(id, input);

        if (!this.customer && this.customerAccessToken) {
          await this.getCustomer();
        }

        return customerActivate;
      } catch (e) {
        return e;
      } finally {
        this.loading = false;
      }
    },
    async activateByUrl(activationUrl: string, password: string) {
      try {
        this.loading = true;
        this.customerUserErrors = [];
        await this.customerActivateByUrl(activationUrl, password);

        if (!this.customer && this.customerAccessToken) {
          await this.getCustomer();
        }
      } catch (e) {
        return e;
      } finally {
        this.loading = false;
      }
    },
    async updateDefaultAddress(address: MailingAddressInput, id: string) {
      try {
        this.loading = true;

        const { data } = await useMutation(customerAddressUpdate, {
          address,
          id,
          customerAccessToken: this.customerAccessToken?.accessToken,
        });

        if (data?.customerAddressUpdate?.customerUserErrors?.length) {
          this.customerUserErrors =
            data.customerAddressUpdate.customerUserErrors;
        }

        return data.customerAddressUpdate?.customerAddress;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    async addNewAddress(address: MailingAddressInput) {
      try {
        this.loading = true;

        const { data } = await useMutation(customerAddressCreate, {
          address,
          customerAccessToken: this.customerAccessToken?.accessToken,
        });

        if (data?.customerAddressCreate?.customerUserErrors?.length) {
          this.customerUserErrors =
            data.customerAddressCreate.customerUserErrors;
        }

        return data.customerAddressCreate?.customerAddress;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    async customerRecover(email: string) {
      try {
        const { data } = await useMutation(customerRecover, {
          email,
        });

        if (data.customerRecover?.customerUserErrors?.length) {
          this.customerUserErrors = data.customerRecover?.customerUserErrors;
        }
      } catch (e) {
        return e;
      }
    },
    async customerAccessTokenCreate(input: CustomerAccessTokenCreateInput) {
      try {
        const { data } = await useMutation(customerAccessTokenCreate, {
          input: {
            email: input.email,
            password: input.password,
          },
        });

        if (data?.customerAccessTokenCreate?.customerAccessToken) {
          this.customerAccessToken =
            data.customerAccessTokenCreate.customerAccessToken;
        }

        if (data?.customerAccessTokenCreate?.customerUserErrors?.length) {
          this.customerUserErrors =
            data.customerAccessTokenCreate.customerUserErrors;
        }
      } catch (e) {
        return e;
      }
    },
    async customerAccessTokenCreateWithMultipass(
      multipassToken: string | string[]
    ) {
      try {
        const { data } = await useMutation(
          customerAccessTokenCreateWithMultipass,
          {
            multipassToken,
          }
        );

        if (data?.customerAccessTokenCreateWithMultipass?.customerAccessToken) {
          this.customerAccessToken =
            data.customerAccessTokenCreateWithMultipass.customerAccessToken;
        }

        if (
          data?.customerAccessTokenCreateWithMultipass?.customerUserErrors
            ?.length
        ) {
          this.customerUserErrors =
            data.customerAccessTokenCreateWithMultipass.customerUserErrors;
        }
      } catch (e) {
        return e;
      }
    },
    async customerAccessTokenDelete(input: CustomerAccessTokenDeleteInput) {
      try {
        const { data } = await useMutation(customerAccessTokenDelete, {
          customerAccessToken: input.accessToken,
        });

        if (data?.customerAccessTokenDelete?.deletedAccessToken) {
          this.customerAccessToken = null;
        }

        if (data?.customerAccessTokenDelete?.userErrors?.length) {
          this.customerUserErrors = data.customerAccessTokenDelete.userErrors;
        }
      } catch (e) {
        return e;
      }
    },
    async getCustomer() {
      const { data } = await useQuery(
        getCustomer,
        {
          customerAccessToken: this?.customerAccessToken?.accessToken,
        },
        {
          requestPolicy: "cache-and-network",
        }
      );

      if (data?.customer) {
        this.customer = data.customer;
      } else {
        await this.logout();
      }
      this.checkAccessToken();
    },
    async checkAccessToken() {
      if (!this.customerAccessToken?.accessToken) {
        await this.logout();
      }

      if (dayjs(this.customerAccessToken?.expiresAt).isBefore(dayjs())) {
        await this.logout();
      }
    },
    async checkEmail() {
      if (
        this?.customer?.email?.toLowerCase() !==
        useTheme().customer?.email.toLowerCase()
      ) {
        return this.logout();
      }
    },
    async customerActivateById(id: string, input: CustomerActivateInput) {
      try {
        const { data } = await useMutation(customerActivate, {
          id,
          input,
        });

        if (data?.customerActivate?.customerUserErrors?.length) {
          this.customerUserErrors = data.customerActivate.customerUserErrors;
        }

        if (data?.customerActivate?.customerAccessToken) {
          this.customerAccessToken = data.customerActivate.customerAccessToken;
        }

        return data;
      } catch (e) {
        return e;
      }
    },
    async customerActivateByUrl(activationUrl: string, password: string) {
      try {
        const { data } = await useMutation(customerActivateByUrl, {
          activationUrl,
          password,
        });

        if (data?.customerActivateByUrl?.customerUserErrors?.length) {
          this.customerUserErrors =
            data.customerActivateByUrl.customerUserErrors;
        }

        if (data?.customerActivateByUrl?.customerAccessToken) {
          this.customerAccessToken =
            data.customerActivateByUrl.customerAccessToken;
        }

        return data;
      } catch (e) {
        return e;
      }
    },
    async resetPasswordByUrl(resetUrl: string, password: string) {
      try {
        this.loading = true;
        this.customerUserErrors = [];
        await this.customerResetPasswordByUrl(resetUrl, password);

        if (!this.customer && this.customerAccessToken) {
          await this.getCustomer();
        }
      } catch (e) {
        return e;
      } finally {
        this.loading = false;
      }
    },
    async customerResetPasswordByUrl(resetUrl: string, password: string) {
      try {
        const { data } = await useMutation(resetPasswordByUrl, {
          resetUrl,
          password,
        });

        if (data?.customerResetByUrl?.customerUserErrors?.length) {
          this.customerUserErrors = data.customerResetByUrl.customerUserErrors;
        }

        if (data?.customerResetByUrl?.customerAccessToken) {
          this.customerAccessToken =
            data.customerResetByUrl.customerAccessToken;
        }

        return data;
      } catch (e) {
        return e;
      }
    },
  },
  getters: {
    firstName: (state) => {
      return (
        state.customer?.firstName || state.customer?.defaultAddress?.firstName
      );
    },
    lastName: (state) => {
      return (
        state.customer?.lastName || state.customer?.defaultAddress?.lastName
      );
    },
    isSignedIn: (state) => {
      return !!window.theme.customer && !!state.customerAccessToken;
    },
    decodedId: (state) => {
      if (!state.customer?.id) return null;

      return parseGid(state.customer.id);
    },
    provinceCode: (state) => {
      return state?.customer?.addresses?.nodes?.find(
        ({ provinceCode }) => provinceCode
      )?.provinceCode;
    },
    errors: (state) => {
      return state.customerUserErrors.map((error) => {
        if (error.code !== "UNIDENTIFIED_CUSTOMER") return error;

        return {
          ...error,
          message: "Incorrect email or password.",
        };
      });
    },
  },
  persist: {
    paths: ["customer", "customerAccessToken"],
  },
});
