import { gql } from "@urql/core";
import { customer } from "../fragments/customer";
import { orders } from "../fragments/orders";

export const getCustomer = gql`
  ${customer}
  ${orders}
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      ...customer
      ...orders
    }
  }
`;
